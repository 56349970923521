.configure-page {
    display: flex;
    width: 100%;
    padding: 1rem;
    height: calc(100vh - 75px);
    position: relative;
    flex-direction: column;

}

.configpage-title {
    font-size: 1rem;
    font-weight: 600;
    margin: 1rem 0;
}

.ck.ck-content {
    min-height: 7rem;
}
.editor-container{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 1rem;
    border: 1px solid #ececec;
    padding: 1rem ;
    background-color: #ececec;
    border-radius: 10px;
    .form-group{
        width:80%;
        display: flex;
        flex-direction: column;
        label{
            margin-bottom: 10px;
        }
    }
}

.left-sidebar{
    background: #fff;
    box-shadow: 0 0 5px 0 rgba(37, 117, 252,  .20);
    height: 100%;
    left: 0;
    position: fixed;
    top: 73px;
    width: 300px;
    padding: 10px;
}
.right-sidebar{
    padding-left: 320px;
    width: 100%;
    .customization-container {
        max-height: 85vh;
        overflow-y: auto;
    }
}
.kiosk-current-image {
    max-width: 20rem;
    height: auto;
    border-radius: 0.5rem;
    height: 6rem;
    object-fit: cover;
}
.actions-container{
    display: flex;
    gap: 10px;
    margin-top: 1.85rem;
}
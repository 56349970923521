.editmenu-container {
    display: flex;
    width: 100%;
    padding: 0 0.5rem;
    height: calc(100vh - 63px);
    position: relative;

    .add-menu-options {
        display: flex;
        justify-content: space-between;
        .menus-wrapper,
        .categories-wrapper {
            gap: 1rem;
            flex: 1;
            padding: 0 0.75rem;
            border-right: 1px solid #dae4ff;
            overflow-y: auto;
            max-width: 21rem;
        }
    }
    .menu-item-view {
        flex: 1;
    }
    .menu-item-view {
        overflow-y: auto;
    }
}

.item-image-wrapper {
    position: relative;
    display: inline-block;
    .item-image {
        width: 100%;
        border-radius: 1rem;
        max-width: 12rem;
        min-height: 9rem;
        object-fit: cover;
        border: 1px solid #dae4ff;
        max-height: 9rem;
        object-fit: cover;
        &.remove {
            position: relative;
            &::after {
                content: '';
                position: absolute;
                top: 0;
                width: 1px;
                height: 100%;
                background-color: red;
            }
        }
    }

    .btn-icon {
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        padding: 0.25rem;
        margin-top: 0.25rem;
        font-size: 0.85rem;
        position: absolute;
        top: 5px;
        right: 5px;
    }
}

.addons-view {
    flex: 1;
    max-width: 19rem;
    .subaddon-card {
        width: 100%;
        margin-bottom: 1rem;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 0.75rem;
        position: relative;
        overflow: hidden;
        box-shadow: 0 0 5px 0 rgba(37, 117, 252, 0.2);
        background-position: center center;
        background-color: #ffc800;
        border-color: #ffc800;
        cursor: pointer;
        &.selected {
            border: 2px solid #1a502cf1;
            .subaddon-name-wrapper {
                background-color: #1a502cf1;
                color: #fff;
            }
        }
        .subaddon-name-wrapper {
            padding: 0.25rem 0.5rem;
            color: #000;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .subaddon-name {
                font-size: 0.75rem;
                word-break: break-word;
            }
            .price-action-container .btn-icon {
                border-radius: 50%;
                width: 2rem;
                height: 2rem;
                padding: 0.25rem;
                margin-left: 0.25rem;
                font-size: 0.85rem;
            }
        }
    }
}
.addons-wrapper {
    border-radius: 1rem;
    padding: 0.85rem;
    background: #c6c6c6;
    color: #000;
    display: flex;
    flex-direction: column;
    padding: 0.75rem;
    width: 100%;
    border: 1px solid #e3ebf6;
    margin-bottom: 0.5rem;
    width: 100%;
    position: relative;
    flex: 1;

    &::before {
        content: '';
        position: absolute;
        top: -9px;
        left: 1rem;
        width: 1rem;
        height: 1rem;
        background: #000;
        transform: rotate(45deg);
        background: #c6c6c6;
        border-left-width: 1px;
        border-top-width: 1px;
        border-color: #e3ebf6;
    }
    .addon-card {
        width: 100%;
        margin-bottom: 1rem;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 0.75rem;
        position: relative;
        overflow: hidden;
        box-shadow: 0 0 5px 0 rgba(37, 117, 252, 0.2);
        background-position: center center;
        background-color: #ffc800;
        border-color: #ffc800;
        cursor: pointer;
        &.selected {
            border: 2px solid #1a502cf1;
            .subaddon-name-wrapper {
                background-color: #1a502cf1;
                color: #fff;
            }
        }
        .addon-name-wrapper {
            padding: 0.25rem 0.5rem;
            color: #000;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .addon-name {
                font-size: 0.75rem;
                word-break: break-word;
            }
            .price-action-container .btn-icon {
                border-radius: 50%;
                width: 2rem;
                height: 2rem;
                padding: 0.25rem;
                margin-left: 0.25rem;
                font-size: 0.85rem;
            }
        }
    }
}

.chart-container {
    height: 420px;
}

.revenue-overview-card {
    margin: 0px 10px 20px 10px;
}

.order-details-container {
    display: flex;
    justify-content: space-between;
    gap: 4rem;
    margin: 0rem 0.7rem 2rem 0.7rem;
}

.metric-card {
    flex: 1;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    transition: transform .2s; /* Animation */
}

.metric-card:hover{
    transform: scale(1.05);
    cursor: pointer;
}

.metric-card-value {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.metric-card-key {
    font-size: 16px;
    font-weight: bold;
    opacity: 0.8;
}

.item-container{
    display: flex;
    gap: 2rem;
}
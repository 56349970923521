.item-card {
    border-radius: 1rem;
    padding: 0.85rem;
    background: #fff;
    color: #000;
    display: flex;
    flex-direction: row;
    padding: 0.75rem;
    width: 100%;
    border: 1px solid #e3ebf6;
    //   box-shadow: 0 0 2px #c9c9c9;
    margin-bottom: 0.5rem;
    cursor: pointer;
    &.selected {
        border: 2px solid #1a502c;
        .category-name-wrapper {
            background-color: #1a502cf1;
            color: #fff;
        }
    }
    &.disabled {
        background-color: #aaaaaa;
    }
    .img-wrapper {
        margin-right: 0.75rem;
        min-width: 5rem;
        img {
            width: 100%;
            border-radius: 1rem;
            max-width: 5rem;
            min-height: 5rem;
            object-fit: cover;
        }
    }
    .item-details-container {
        .item-name {
            font-size: 15px;
            text-align: left;
            margin-bottom: 0.25rem;
        }
        .item-description {
            font-size: 12px;
            text-align: left;
            color: #7f7f7f;
            line-height: 1.4;
            margin-bottom: 5px;
        }
        .item-code {
            font-size: 11px;
            text-align: left;
            letter-spacing: 0.5px;
            color: #000;
        }
        .price {
            font-size: 14px;
            font-weight: 600;
        }
    }

    .price-action-container {
        display: flex;
        flex-direction: column;
        margin-left: auto;
        align-items: flex-start;
        justify-content: flex-start;

        .btn-icon {
            border-radius: 50%;
            width: 2rem;
            height: 2rem;
            padding: 0.25rem;
            margin-top: 0.25rem;
            font-size: 0.85rem;
            .pi-plus {
                font-weight: 600;
            }
        }
    }
}

.menu-item-view {
    padding: 0 1rem;
}
.header-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    position: sticky;
    top: 0;
    background: #fff;
    padding: 0.4rem 0;
    z-index: 9;
}
// .menu-card {
//   width: 100%;
//   height: 10rem;
//   margin-bottom: 1rem;
//   background-repeat: no-repeat;
//   background-size: cover;
//   border-radius: 1rem;
//   min-width: 20rem;
//   position: relative;
//   overflow: hidden;
//   box-shadow: 0 0 5px 0 rgba(37, 117, 252, 0.2);
//   background-position: center center;
//   cursor: pointer;
//   &.selected {
//     border: 2px solid #1a502cf1;
//     .menu-name-wrapper {
//       background-color: #1a502cf1;
//       color: #fff;
//     }
//   }
//   .menu-name-wrapper {
//     position: absolute;
//     bottom: 0px;
//     left: 0;
//     padding: 0.25rem 0.5rem;
//     background-color: rgba(255, 200, 0, 0.8);
//     border-color: rgba(255, 200, 0, 0.8);
//     color: #000;
//     width: 100%;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     .menu-name {
//       font-size: 0.75rem;
//     }

//     .price-action-container {
//       display: flex;
//       flex-direction: row;
//       .btn-icon {
//         border-radius: 50%;
//         width: 2rem;
//         height: 2rem;
//         padding: 0.25rem;
//         margin-left: 0.25rem;
//         font-size: 0.85rem;
//       }
//     }
//   }
// }
.menu-card {
    width: 100%;
    margin-bottom: 1rem;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0.75rem;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 5px 0 rgba(37, 117, 252, 0.2);
    background-position: center center;
    // background-color: #1a502cf1;
    background-color: #ffc800;
    border-color: #ffc800;

    cursor: pointer;
    &.selected {
        border: 2px solid #1a502cf1;
        .menu-name-wrapper {
            background-color: #1a502cf1;
            color: #fff;
        }
    }
    &.disabled {
        background-color: #aaaaaa;
        border-color: #aaaaaa;
    }
    .menu-name-wrapper {
        padding: 0.25rem 0.5rem;
        color: #000;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .menu-name {
            font-size: 0.75rem;
            word-break: break-word;
        }

        .price-action-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            .btn-icon {
                border-radius: 50%;
                width: 2rem;
                height: 2rem;
                padding: 0.25rem;
                margin-left: 0.25rem;
                font-size: 0.85rem;
            }
        }
    }
}
.category-card {
    width: 100%;
    height: 8rem;
    margin-bottom: 1rem;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 1rem;
    // min-width: 20rem;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 0 5px 0 rgba(37, 117, 252, 0.2);
    background-size: contain;
    background-position: center center;
    background-color: #fff;
    &.selected {
        border: 2px solid #1a502c;
        .category-name-wrapper {
            background-color: #1a502cf1;
            color: #fff;
        }
    }
    &.disabled {
        .category-name-wrapper {
            background-color: #aaaaaa;
            border-color: #aaaaaa;
        }
    }
    .category-name-wrapper {
        position: absolute;
        bottom: 0px;
        left: 0;
        padding: 0.25rem 0.5rem;
        background-color: rgba(255, 200, 0, 0.8);
        border-color: rgba(255, 200, 0, 0.8);
        color: #000;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .category-name {
            font-size: 0.75rem;
            word-break: break-word;
        }

        .price-action-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            .btn-icon {
                border-radius: 50%;
                width: 2rem;
                height: 2rem;
                padding: 0.25rem;
                margin-left: 0.25rem;
                font-size: 0.85rem;
            }
        }
    }
}
